.balance-card {
  margin-top: 1rem;
  width: 100%;
  background-color: #10396a;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balance-card p {
  margin: 0;
  margin-bottom: 8px;
  text-align: center;
}

.balance-card h1 {
  margin: 0;
  text-align: center;
}

.balance-left {
  flex: 0.5;
  border-right: 2px solid #ccc;
}

.balance-right {
  flex: 0.5;
}

.tools-card img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.tools-card .logo-box {
  background-color: #2a5d9b;
  width: 48px;
  height: 48px;
  border-radius: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools-card h4 {
  width: 80%;
  text-align: center;
  margin-top: 8px;
  font-size: 1rem;
}

.tool-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tool-left {
  flex: 0.33;
  border-right: 2px solid #ccc;
}
.tool-center {
  flex: 0.33;
  border-right: 2px solid #ccc;
}
.tool-right {
  flex: 0.33;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-tabs-tab.ant-tabs-tab-active {
  z-index: 2;
  border-radius: 20px;
}

/* .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
} */

.ant-spin .ant-spin-dot-item {
  background: white !important;
}

.ant-empty-description {
  color: white !important;
}

@media screen and (max-width: 1280px) {
  .balance_btns {
    text-align: center;
    /* width: 85%; */
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
    align-items: flex-end;
  }
}

/* Styles for mobile view */
@media (max-width: 767px) {
  .balance_btns {
    text-align: center;
    /* width: 120%; */
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.balance_btns .btns {
  text-align: center;
  background-image: linear-gradient(to bottom right, #073d80, #2e5a8f);
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
/* .ant-modal-content {
  background: #093161 !important;
  min-height: 390px;
} */

.ant-modal-title {
  color: #ffc107 !important;
  font-weight: bold;
  font-size: 18px;
}

.referral_icon {
  width: 20%;
  display: flex;
  /* color: white; */
  font-weight: bold;
  align-items: center;
  cursor: pointer;
}

.ant-modal-header {
  background: transparent !important;
}

/* .ant-modal-title {
  color: white !important;
} */
.ant-modal-close {
  color: white !important;
}
.modal-box .ant-input-password {
  background: transparent !important;
}

.modal-box input {
  background: transparent !important;
  color: white !important;
}

.modal-box input::placeholder {
  color: #8f8f8f;
}

.modal-box .ant-input-password-icon {
  color: white !important;
}

/* :where(.css-1wazalj).ant-modal .ant-modal-content {
  border: 2px solid #ffc107;
  border-radius: 15px;
  } */

/* :where(.css-dev-only-do-not-override-1wazalj).ant-modal .ant-modal-content {
  border: 2px solid #ffc107;
  border-radius: 15px;
  } */

.app-accounts-container {
  padding: 1rem;
}

.app-accounts-container .account-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.account-item .account-content {
  width: 100%;
  height: 100px;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.account-content p {
  font-size: 1rem;
  width: 100%;
  font-weight: 500;
  margin-bottom: 0;
}
.account-content .pass {
  margin-top: 0;
  border: none;
  width: 70%;
  outline: none;
  background-color: rgba(0, 0, 0, 1);
  margin-top: 4px;
  font-weight: 500;
  color: white;
}

.account-content .pass input {
  background-color: transparent;
  color: white;
}

.account-content .pass .ant-input-password-icon {
  color: white;
}

.account-item h3 {
  width: 50%;
  text-align: right;
  font-weight: bold;
  font-size: 1.1rem;
}
.account-item h3 > span {
  font-size: 0.8rem;
  font-weight: 300;
}
.account-item img {
  width: 100px;
  height: 100px;
  border-radius: 1rem;
}

.img-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.ant-modal-content {
  width: 100%;
  background: black !important;
  border: 2px solid #ffc107 !important;
  margin: 0 auto;
}

.started-game-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.started-game-footer {
  background: linear-gradient(to left, #2e2e2e, #000);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  max-width: 475px;
  border: 1px solid #ffc107;
}

.started-game-context {
  display: flex;
}

.started-game-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
}

.started-game-footer button {
  background: #ffc107;
  border: none;
  border-radius: 8px;
  color: black;
}

.started-game-footer .provider-box {
  display: flex;
  align-items: center;
}

.provider-box p {
  margin-right: 4px;
}

.provider-box .live-json {
  width: 40px;
}

.MuiTab-root {
  border-radius: 4px;
  color: white !important;
  margin-right: 8px !important;
  padding: 6px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  /* background: rgb(205, 159, 21); */
  /* color: white !important; */
  border-radius: 6px;
}

.MuiTabs-indicator {
  display: none;
}
.promo_title {
  font-weight: bold;
  font-size: 24px; /* Adjust the font size as needed */
  color: yellow;
  align-self: center;
  margin-top: 10px;
}
.promo_modal img {
  width: 100% !important;
  height: auto !important;
}
.promo_dropdown img {
  width: 80% !important;
  height: auto !important;
}
.jackpot-ticker-container {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  margin-top: 0;
  /* box-shadow: 
  0 0 12px rgba(230, 150, 12, 0.8),
  0 0 12px rgba(230, 150, 12, 0.8) inset;  */
  flex-wrap: wrap;
}

.jackpot-ticker-container .subtitle {
  font-size: .9rem;
  color: #fff !important;
  margin-bottom: -8px !important;
}
.jackpot-ticker-container .title {
  font-size: 1.4rem;
  letter-spacing: 4px;
}

.jackpot-ticker-container .left {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jackpot-ticker-container .countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
}

.countdown-container .symbol {
  color: #ffc107 !important;
}

.jackpot-ticker-container-image-subtitle {
  font-size: .9rem;
  color: #fff !important;
  margin-bottom: -8px !important;
}
.jackpot-ticker-container-image-title {
  font-size: 1.4rem;
  letter-spacing: 4px;
}

.jackpot-ticker-container-image-left {
  margin-left: 10px;
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-logout{
  background: #7C7C7C 0% 0% no-repeat;
    box-shadow: 0px 3px 3px #00000029;
    border-radius: 2px !important;
}
.bg-dashboard{
  box-shadow: 0px 3px 6px #00000080;
    /* background-image: url(/images/assets/v3/member/profile-texture.jpg); */
    background-size: cover;
    /* border: 2px solid; */
}
.profile-winover{
  color: #E1E1E1;
  font-size: 0.9rem;
  border: 2px solid #E6960C;
  border-radius: 15px;
  box-shadow: 
  0 0 12px rgba(230, 150, 12, 0.8), /* Outer shadow */
  0 0 12px rgba(230, 150, 12, 0.8) inset; /* Inner shadow */
}
.avatar_info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.avatar_image {
  border-radius: 100%;
  border: 1px solid #2691f3;
}
.user_details button{
  background: #7C7C7C 0% 0% no-repeat;
  box-shadow: 0px 3px 3px #00000029;
  color: white;
}
.profile-progressbar-outer{
  background: #4d4d4d;
  display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    /* background-color: #e9ecef; */
    border-radius: 0.25rem;
}
.profile-progressbar-bar{
  box-shadow: 0px 0px 4px #5C5C5C;
}
.profile-winover .btn-1{
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
} 
.square {
  position: relative;
  /* width: 50%; */
  border: 2px solid #E6960C;
  box-shadow: 0px 3px 6px #00000080;
}
.square::after{
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square a{
  position: absolute;
    width: 100%;
    height: 100%;
}
/* steps progress */

.progressbar {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin: 0;
}

.step {
  width: 30.33%;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.step.active .step-number {
  color: #fff;
  background-color: green;
}

.step-number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
  z-index: 2;
}

.step .hr-line {
  height: 2px;
  background-color: green;
  width: 50%;
  position: absolute;
  right: 0;
  top: 15px;
}
.step:last-child .hr-line {
  left: 0;
}
.vertical_selected .ant-tabs-nav-list .ant-tabs-tab{
  width: 89px;
  height: 89px;
}
.vertical_selected .ant-tabs-tab-btn{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}